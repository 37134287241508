import { acceptHMRUpdate, defineStore } from 'pinia'
import type { AuthStateInterface, UserStateInterface } from 'src/stores/auth/types'
import type { FilterInterface, SavedFilterInterface } from 'stores/types'
import { uid } from 'quasar'

export const useAuthStore = defineStore('auth', {
  state: (): AuthStateInterface => ({
    token: null,
    user: null,
    userSettings: {
      filters: {},
      language: null,
      currencyCode: null,
      dateFormat: null,
    },
  }),
  getters: {
    isSignedIn: (state: AuthStateInterface) => !!state.token,
    filters: (state: AuthStateInterface) => state.userSettings.filters,
    language: (state: AuthStateInterface) => state.userSettings.language,
    currencyCode: (state: AuthStateInterface) => state.userSettings.currencyCode,
    dateFormat: (state: AuthStateInterface) => state.userSettings.dateFormat,
  },

  actions: {
    setToken(token: string) {
      this.token = token
    },

    setUser(user: UserStateInterface) {
      this.user = user
      if (!user) {
        return
      }
      if (user.settings) {
        const filters = user.settings.find((setting) => setting.key === 'filters')
        if (filters) {
          for (const [key, value] of Object.entries(filters.data)) {
            this.saveFilters({ filterKey: key, filters: value })
          }
        }
      }
    },

    saveFilters({ filterKey, filters }: { filterKey: string; filters: SavedFilterInterface[] }) {
      state.userSettings.filters[filterKey] = filters
    },

    saveFilter({
      filterKey,
      filter,
      name,
      visibleColumns,
      sortColumns,
    }: {
      filterKey: string
      filter: FilterInterface[]
      name: string
      visibleColumns: string[]
      sortColumns: string[]
    }) {
      if (!this.userSettings.filters[filterKey]) {
        this.userSettings.filters[filterKey] = []
      }

      const filterObject = {
        filterId: uid(),
        name: name,
        filter: filter,
        visibleColumns: visibleColumns,
        sortColumns: sortColumns,
      }

      this.userSettings.filters[filterKey].push(filterObject)
    },

    deleteFilter({ filterKey, filterId }: { filterKey: string; filterId: string }) {
      const filterIndex = this.userSettings.filters[filterKey].findIndex(
        (userFilter) => userFilter.filterId === filterId,
      )

      this.userSettings.filters[filterKey].splice(filterIndex, 1)
    },

    logout() {
      this.setToken(null)
      this.setUser(null)
    },
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
