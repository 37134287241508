import { route } from 'quasar/wrappers'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import routes from './routes'

import type { StateInterface } from 'src/stores/types'
import type { UserStateInterface } from 'src/stores/auth/types'
import useAbility from 'src/hooks/UseAbility'
import { useAuthStore } from 'src/stores/auth'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route<StateInterface>(() => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({
      left: 0,
      top: 0,
    }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  })

  Router.beforeEach(
    (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const { permissionAsString } = useAbility()

      const authUserStore = useAuthStore()
      if (!to.meta.requireAuth) {
        next()
        return
      }

      if (!authUserStore.isSignedIn) {
        void Router.push('login')
        return
      }

      const user: UserStateInterface = authUserStore.user

      if (!to.meta.permission) {
        next()
        return
      }

      let permissions = to.meta.permission
      if (!Array.isArray(permissions)) {
        permissions = [permissions]
      }

      for (const permissionObject of permissions) {
        const permission = permissionAsString(permissionObject)

        if (
          user.allPermissions.map((singlePermission) => singlePermission.name).includes(permission)
        ) {
          next()
          return
        }
      }
      void Router.push(from.path + '?permission_denied=1')
      return
    },
  )

  return Router
})
